export default {
  paginatedData: {
    current_page: null,
    data: [],
    from: null,
    last_page: null,
    per_page: null,
    prev_page_url: null,
    to: null,
    total: null
  },
  sort: '',
  attributeId: '',
  page: 1,
  filters: {},
  queryString: '',

  editItem: {}
}
