export default {
  paginatedData: [],
  sort: '',
  page: 1,
  filters: {},
  queryString: '',

  editItem: {}
}

