import axios from '@/axios'

export default {
  updateItem ({dispatch}, itemData) {
    return new Promise((resolve, reject) => {
      axios.post(`/product-brands/${itemData.id}`, itemData.form, {headers: {'Content-Type': 'multipart/form-data'}})
        .then((response) => {
          dispatch('loadItems').then(() => {
            dispatch('sidebar/closeSidebar', null, {root: true})
            dispatch('stopLoading', null, {root: true})
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          dispatch('stopLoading', null, {root: true})
        })
    })

  },
  editItem ({dispatch, commit}, item) {
    dispatch('sidebar/setCurrentActiveSidebarComponent', {
      component: 'edit-client-reward',
      title: 'Editeaza beneficiul'
    }, {root: true})
    commit('SET_EDIT_ITEM', item)
  }
}
