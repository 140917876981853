export default {
  openSidebar({ commit }, payload) {
    commit("OPEN_SIDEBAR", payload);
  },
  closeSidebar({ commit }) {
    commit("CLOSE_SIDEBAR");
    commit("SET_CURRENT_ACTIVE_SIDEBAR_COMPONENT", "");
  },
  startLoading({ commit }) {
    commit("START_LOADING");
  },
  stopLoading({ commit }) {
    commit("STOP_LOADING");
  },
  setCurrentActiveSidebarComponent({ commit, dispatch }, payload) {
    const component = payload.component || "";
    const confirmationButton = payload.confirmationButton || "Creaza";
    const title = payload.title || "";
    commit("SET_CURRENT_ACTIVE_SIDEBAR_COMPONENT", component);
    commit(
      "SET_CURRENT_ACTIVE_SIDEBAR_CONFIRMATION_BUTTON",
      confirmationButton
    );

    dispatch("openSidebar", title);
  },
};
