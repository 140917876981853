<template>
  <v-autocomplete
    v-model="selectOption"
    :dense="dense"
    :hide-details="hideDetails"
    :items="counties"
    :label="label"
    :loading="loading"
    :outlined="outlined"
    hide-details
    hide-no-data
    item-text="name"
    item-value="id"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'CountySelector',
  props: {
    value: {},
    zone: {},
    includeAll: {
      default: false
    },
    label: {
      default: 'Judet'
    },
    dense: {
      default: false,
      type: Boolean
    },
    hideDetails: {
      default: false,
      type: Boolean
    },
    outlined: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      paginatedData: {
        data: []
      },
      loading: false
    }
  },
  computed: {
    counties () {
      return this.paginatedData.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  methods: {
    loadItems () {
      const countyFilter = this.includeAll ? '' : `&filter[zone]=${this.zone}`
      this.$http.get(`/location/counties?sort=name&page_size=all${countyFilter}`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>
