<template>
  <div>
    <form autocomplete="off">


      <v-row >

        <v-col cols="6">

          <v-card class="mb-4" elevation="2">
            <v-card-title>Detalii companie</v-card-title>
            <v-card-text>
              <div>
                <v-text-field v-model="form.franchisee.name" label="Nume" />
                <error-messages :errors="errors['franchisee.name']" />
              </div>

              <div>
                <v-text-field v-model="form.franchisee.domain" label="Shop Domain" />
                <error-messages :errors="errors['franchisee.domain']" />
              </div>

              <div>
                <v-text-field v-model="form.franchisee.admin_domain" label="Admin Domain" />
                <error-messages :errors="errors['franchisee.admin_domain']" />
              </div>

              <div>
                <v-text-field v-model="form.franchisee.phone" label="Nr. Telefon" />
                <error-messages :errors="errors['franchisee.phone']" />
              </div>

              <div>
                <v-text-field v-model="form.franchisee.email" label="Email" />
                <error-messages :errors="errors['franchisee.email']" />
              </div>

              <div>
                <v-text-field v-model="form.franchisee.logofile_url" type="url" label="Logo URL" />
                <error-messages :errors="errors['franchisee.logofile_url']" />
              </div>

              <div>
                <v-text-field v-model="form.franchisee.fevicon_url" type="url" label="Favicon URL " />
                <error-messages :errors="errors['franchisee.fevicon_url']" />
              </div>
            </v-card-text>
          </v-card>

          <v-card elevation="2">
            <v-card-title>Administrator</v-card-title>
            <v-card-text>
              <div>
                <v-text-field v-model="form.franchisee.admin.first_name" label="Prenume" />
                <error-messages :errors="errors['client.admin.first_name']" />
              </div>

              <div>
                <v-text-field v-model="form.franchisee.admin.last_name" label="Nume" />
                <error-messages :errors="errors['client.admin.last_name']" />
              </div>

              <div>
                <v-text-field v-model="form.franchisee.admin.email" label="Email" />
                <error-messages :errors="errors['client.admin.email']" />
              </div>

              <div>
                <v-text-field v-model="form.franchisee.admin.phone" label="Nr. de telefon" />
                <error-messages :errors="errors['client.admin.phone']" />
              </div>

            </v-card-text>
          </v-card>

        </v-col>

        <v-col cols="6">
          <v-card class="mb-4" elevation="2">
            <v-card-title>Date facturare</v-card-title>
            <v-card-text>

              <v-row>  

              <v-col cols="6">   
              <div>
                <v-text-field v-model="form.franchisee.invoice_details.company_name" label="Nume companie" />
                <error-messages :errors="errors['franchisee.invoice_details.company_name']" />
              </div>
              </v-col>
            
              <v-col cols="6">  
              <div>
                <v-text-field v-model="form.franchisee.invoice_details.register_order_number"
                  label="Nr. ord. registru com./an" />
                <error-messages :errors="errors['franchisee.invoice_details.register_order_number']" />
              </div>
              </v-col>

              <v-col cols="6">  
              <div>
                <v-text-field v-model="form.franchisee.invoice_details.tax_registration_number"
                  label="Nr. de inregistrare fiscala" />
                <error-messages :errors="errors['franchisee.invoice_details.tax_registration_number']" />
              </div>
              </v-col>

              <v-col cols="6">  
              <div>
                <v-text-field v-model="form.franchisee.invoice_details.company_address" label="Sediul" />
                <error-messages :errors="errors['franchisee.invoice_details.company_address']" />
              </div>
              </v-col>

              <v-col cols="6">  
              <div>
                <v-text-field v-model="form.franchisee.invoice_details.county" label="Judetul" />
                <error-messages :errors="errors['franchisee.invoice_details.county']" />
              </div>
              </v-col>

              <v-col cols="6">  
              <div>
                <v-text-field v-model="form.franchisee.invoice_details.bank_name" label="Banca" />
                <error-messages :errors="errors['franchisee.invoice_details.bank_name']" />
              </div>
              </v-col>

              <v-col cols="6">  
              <div>
                <v-text-field v-model="form.franchisee.invoice_details.bank_account" label="Contul" />
                <error-messages :errors="errors['franchisee.invoice_details.bank_account']" />
              </div>
              </v-col>

              <v-col cols="6">  
              <div>
                <v-text-field v-model="form.franchisee.invoice_details.phone" label="Telefon" />
                <error-messages :errors="errors['franchisee.invoice_details.phone']" />
              </div>
              </v-col>

              <v-col cols="6">  
              <div>
                <v-text-field v-model="form.franchisee.invoice_details.fax" label="Fax" />
                <error-messages :errors="errors['franchisee.invoice_details.fax']" />
              </div>
              </v-col>

              <v-col cols="6">  
              <div>
                <v-text-field v-model="form.franchisee.invoice_details.website" type="url" label="Website " />
                <error-messages :errors="errors['franchisee.invoice_details.website']" />
              </div>
              </v-col>

              </v-row>

            </v-card-text>
          </v-card>

        </v-col>

      </v-row>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'AddFranchisee',
  components: {
    ErrorMessages
  },
  data() {
    return {
      form: {
        franchisee: {
          name: '',
          domain: '',
          admin_domain: '',
          email: '',
          phone: '',
          logofile_url: '',
          fevicon_url: '',
          invoice_details: {
            company_name: "",
            register_order_number: "",
            tax_registration_number: "",
            company_address: "",
            county: "",
            bank_account: "",
            bank_name: "",
            phone: "",
            fax: "",
            website: "",
          },
          admin: {
            first_name: '',
            last_name: '',
            email: '',
            phone: ''
          }
        }
      },
      errors: {}
    }
  },
  methods: {
    submitForm() {
      this.$store.dispatch('sidebar/startLoading')
      this.$store.dispatch('franchisees/storeItem', this.form)
        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
          this.$store.dispatch('sidebar/stopLoading')
        })
    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>



.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-input__slot {
  margin-bottom: 0px;
}

.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}

</style>
