import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css' // Vuesax
import VueTheMask from 'vue-the-mask'
// axios
import axios from './axios.js'
// Filters
import './filters/filters.js'
import vueDebounce from 'vue-debounce'
import DatetimePicker from 'vuetify-datetime-picker'
// Theme Configurations
import '../themeConfig.js'
import 'vue-file-agent/dist/vue-file-agent.css'
import VueFileAgent from 'vue-file-agent'

import {SlickItem, SlickList} from 'vue-slicksort'
// eslint-disable-next-line no-unused-vars
// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'

import '@/login'

// Vuejs - Vue wrapper for hammerjs
import {VueHammer} from 'vue2-hammer'
// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import '@fortawesome/fontawesome-pro/js/all'

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';
import vuetify from './plugins/vuetify'
import VueDragscroll from 'vue-dragscroll'
import SettingPageGroup from '@/views/UserGuide/components/SettingPageGroup'
import SettingPageWrapper from '@/views/UserGuide/components/SettingPageWrapper'
import SettingPageItem from '@/views/UserGuide/components/SettingPageItem'

Vue.component('vfa-sortable-list', SlickList)
Vue.component('vfa-sortable-item', SlickItem)


Vue.use(VueFileAgent)


Vue.use(vueDebounce)

Vue.use(VueDragscroll)

// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(DatetimePicker)
const moment = require('moment')
require('moment/locale/ro')
Vue.use(require('vue-moment'), {
  moment
})

Vue.use(Vuesax)

Vue.component('sp-group', SettingPageGroup)
Vue.component('sp-wrapper', SettingPageWrapper)
Vue.component('sp-item', SettingPageItem)

Vue.prototype.$http = axios


Vue.use(VueHammer)

Vue.use(VueTheMask)

// Feather font icon
require('./assets/css/iconfont.css')

Vue.config.productionTip = false

new Vue({
  router,
  store,

  created () {
    const userToken = localStorage.getItem('user-token')
    if (userToken) {
      this.$store.dispatch('auth/setToken', userToken)
    }
    const userString = localStorage.getItem('user') || {}
    const user = JSON.parse(userString)
    if (user) {
      this.$store.commit('auth/SET_USER', user)
    }
  },

  vuetify,
  render: h => h(App)
}).$mount('#app')
