<template>
  <v-autocomplete
    v-model="selectOption"
    :disabled="disabled"
    :items="componentGroups"
    :label="label"
    :loading="loading"
    :multiple="multiple"
    :search-input.sync="search"
    cache-items
    class="filter-select text-sm"
    clearable
    hide-details
    outlined
    dense
    item-text="name"
    item-value="id"
  >
    <template v-slot:append-item v-if="addNewButton">
      <v-divider class="mb-2"></v-divider>
      <v-text-field
        v-model="addNewForm.name"
        :disabled="saving"
        :loading="saving"
        filled
        hide-details
        label="Adauga grup nou"
      >
        <template v-slot:append>
          <v-btn icon @click="addNewComponentGroup">
            <v-icon>
              fa fa-save
            </v-icon>
          </v-btn>
        </template>
      </v-text-field>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'ProductComponentGroupSelector',
  props: {
    value: {},
    multiple: {
      default: false,
      type: Boolean
    },
    label: {
      default: 'Categorie'
    },
    disabled: {
      default: false
    },
    addNewButton:{
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      paginatedData: {
        data: []
      },
      addNewForm: {
        name: ''
      },
      saving: false,
      loading: false,
      search: ''
    }
  },
  computed: {
    componentGroups () {
      return this.paginatedData.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    addNewComponentGroup () {
      this.saving = true
      this.$http.post(`/product-component-groups`, this.addNewForm)
        .then(() => {
          this.search = this.addNewForm.name
          this.addNewForm.name = ''

          this.saving = false
        })
    },
    loadItems (search) {
      search = search || ''
      this.$http.get(`/product-component-groups?filter[name]=${search}`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
