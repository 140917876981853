export default {
  backup: {
    categories: [],
    category_id: '',
    short_name_recipe: '',
    base_product_id: '',
    is_published_to_cit: 1,
    is_published_to_resellers: 1,
    is_published_to_feed: 1,
    qualities: {},
    slots: [],
    selectedWarranties:[],
    selectedExtraOptions: []
  },
  configData: {
    categories: [],
    category_id: '',
    short_name_recipe: '',
    base_product_id: '',
    is_published_to_cit: 1,
    is_published_to_resellers: 1,
    is_published_to_feed: 1,
    qualities: {},
    slots: [],
    selectedWarranties:[],
    selectedExtraOptions: []
  },
  isEditing: false,
  errors: {},
  cachedComponentTypes: [],
  bufferWarranties:[],
  nameGenerationMethods: [
    {
      key: 'base_product_name',
      value: 'Numele produsului de baza'
    },
    {
      key: 'cat_brand_model',
      value: 'Generat: Categorie principala + Brand + model'
    }
  ]
}
