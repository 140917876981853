import axios from '@/axios'

export default {
  AUTH_LOGIN (state, token) {
    state.token = token
    localStorage.setItem('user-token', token)
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },
  AUTH_LOGOUT (state) {
    state.token = ''
    localStorage.removeItem('user-token')
    delete axios.defaults.headers.common['Authorization']
  },
  SET_USER (state, user) {
    state.user = user
    localStorage.setItem('user', JSON.stringify(user))
  }
}
