<template>
  <v-card elevation="2" class="mb-4">
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SettingPageGroup',
  props: {
    title: {
      required: true
    }
  }
}
</script>
