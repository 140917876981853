<template>
  <div>
    <form autocomplete="off">
      <v-card class="mb-4" elevation="2">
        <v-card-title>Date client</v-card-title>
        <v-card-text>

          <div>
            <v-text-field v-model="form.client.first_name" label="Nume"/>
            <error-messages :errors="errors['client.first_name']"/>
          </div>

          <div>
            <v-text-field v-model="form.client.last_name" label="Prenume"/>
            <error-messages :errors="errors['client.last_name']"/>
          </div>

          <div>
            <v-text-field v-model="form.client.phone" label="Nr. Telefon"/>
            <error-messages :errors="errors['client.phone']"/>
          </div>

          <div>
            <v-text-field v-model="form.client.email" label="Email"/>
            <error-messages :errors="errors['client.email']"/>
          </div>

          <div>
            <zone-selector v-model="form.client.zone_id" class="mb-4" label="Zona"/>
            <error-messages :errors="errors['client.zone_id']"/>
          </div>

          <div>
            <county-selector v-model="form.client.county_id" :include-all="true" class="mb-4" label="Judetul"/>
            <error-messages :errors="errors['client.county_id']"/>
          </div>

          <div>
            <city-selector v-model="form.client.city_id" :county="form.client.county_id" class="mb-4"
                           label="Localitatea"/>
            <error-messages :errors="errors['client.city_id']"/>
          </div>

        </v-card-text>
      </v-card>
      <v-card elevation="2">
        <v-card-title>Date Firma</v-card-title>
        <v-card-text>

          <div>
            <v-text-field v-model="form.company.name" label="Nume"/>
            <error-messages :errors="errors['company.name']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.vat" label="Cui"/>
            <error-messages :errors="errors['company.vat']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.reg_number" label="Nr.Reg.Com."/>
            <error-messages :errors="errors['company.reg_number']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.contact_person" label="Persoana de contact"/>
            <error-messages :errors="errors['company.contact_person']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.phone_mobile" label="Nr. de telefon"/>
            <error-messages :errors="errors['company.phone_mobile']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.phone" label="Nr. de telefon (fix)"/>
            <error-messages :errors="errors['company.phone']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.activity" label="Activitate"/>
            <error-messages :errors="errors['company.activity']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.caen" label="Cod Caen"/>
            <error-messages :errors="errors['company.caen']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.caen_explained" label="Explicatie Cod caen"/>
            <error-messages :errors="errors['company.caen_explained']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.net_worth" label="Cifra de afacere"/>
            <error-messages :errors="errors['company.net_worth']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.number_of_employees" label="Numar angajati"/>
            <error-messages :errors="errors['company.number_of_employees']"/>
          </div>

          <div>
            <v-text-field v-model="form.company.website" label="Website"/>
            <error-messages :errors="errors['company.website']"/>
          </div>

        </v-card-text>
      </v-card>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ZoneSelector from '@/components/autocompletes/ZoneSelector'
import CountySelector from '@/components/autocompletes/CountySelector'
import CitySelector from '@/components/autocompletes/CitySelector'

export default {
  name: 'AddCompanyClient',
  components: {
    CitySelector,
    CountySelector,
    ZoneSelector,
    ErrorMessages
  },
  data () {
    return {
      form: {
        client: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          county_id: '',
          zone_id: '',
          city_id: ''
        },
        company: {
          contact_person: '',
          phone: '',
          phone_mobile: '',
          name: '',
          caen: '',
          caen_explained: '',
          net_worth: '',
          number_of_employees: '',
          activity: '',
          website: '',
          vat:'',
          reg_number:''
        }
      },
      errors: {}
    }
  },
  methods: {
    submitForm () {

      this.$store.dispatch('companyClients/storeItem', this.form)
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })

    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
