
export default {
  item (state) {
    return state.item
  },
  company (state) {
    return state.item.company
  }

}
