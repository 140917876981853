export default {
  possibleDiscounts: [
    '-',
    '1.1', '1.2', '1.25', '1.3', '1.4', '1.5', '1.6', '1.7', '1.8', '1.9',
    '2', '2.1', '2.2', '2.3', '2.4', '2.5', '2.6', '2.7', '2.8', '2.9', '3'
  ],
  defaultProductStatus: 'in-stock',
  productStatuses: {
    'in-stock': 'In stoc',
    'new-import': 'Import nou',
    'to-be-received': 'De receptionat'
  },
  productStockStatuses: [
    {
      id: 'in-stock',
      value: 'In stoc'
    },
    {
      id: 'to-be-received',
      value: 'De receptionat'
    }
  ]
}
