<template>
  <div>
    <form ref="form">
      <div class="mb-5">
        <div class="mb-5">
          <v-text-field v-model="form.name" label="Nume"/>
          <error-messages :errors="errors.name"></error-messages>
        </div>
        <div class="mb-5">
          <v-text-field v-model="form.filter_display_order" label="Ordine afisare in filtru"/>
          <error-messages :errors="errors.filter_display_order"></error-messages>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ProductCategorySelector from '@/components/autocompletes/ProductCategorySelector'

export default {
  name: 'AddAttributeValue',
  components: {
    ProductCategorySelector,
    ErrorMessages
  },
  data () {
    return {
      form: {
        name: '',
        filter_display_order: 1
      },
      errors: {}
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('startLoading')
      this.$store.dispatch('attributeValues/storeItem', {
        name: this.form.name,
        filter_display_order: this.form.filter_display_order,
        attribute_id: this.$route.params.id
      })
        .catch(({response}) => {
          this.errors = response.data.errors
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })

    }
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
