<template>
  <v-card flat>
    <v-card-text>
      <v-card-subtitle>
        <h5 class="text-primary font-weight-bold">{{ title }}</h5>
      </v-card-subtitle>
      <v-card-text>
        <slot></slot>
      </v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SettingPageWrapper',
  props: {
    title: {
      required: true,
      default: ''
    }
  }
}
</script>
