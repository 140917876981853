export default {
  SET_CATEGORY_ID (state, categoryId) {
    state.categoryId = categoryId
  },

  SET_FILTERS (state, filters) {
    state.filters = filters
  },

  DELETE_ITEM (state, index) {
    state.filters.splice(index,1)
  },

  ADD_FILTER (state, filter) {
    state.filters.push(filter)
  }
}
