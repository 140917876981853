<template>
  <v-autocomplete
    v-model="selectOption"
    :items="componentTypes"
    :label="label"
    :loading="loading"
    :multiple="multiple"
    :return-object="returnObject"
    :search-input.sync="search"
    class="filter-select text-sm"
    dense
    hide-details
    item-value="id"
    no-filter
    outlined
  >
    <template v-slot:item="{item}">
      <div>
        <strong style="margin-right: 5px">{{ item.name }}</strong>
        (
        <small>
          <span v-for="productCat in item.product_categories" :key="productCat.id">
            {{ productCat.name }},
          </span>
        </small>
        )
      </div>
    </template>

    <template v-slot:selection="{item}">
      <div>
        <strong style="margin-right: 5px">{{ item.name }}</strong>
        (
        <small>
          <span v-for="productCat in item.product_categories" :key="productCat.id">
            {{ productCat.name }},
          </span>
        </small>
        )
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'ProductComponentSelector',
  props: {
    value: {},
    label: {
      default: 'Tipul componentelor'
    },
    returnObject: {
      default: false
    },
    category: {
      default: false
    },
    multiple: {
      default: false
    }
  },
  data () {
    return {
      paginatedComponentTypes: {
        data: []
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    componentTypes () {
      return this.paginatedComponentTypes.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  watch: {
    search (val) {
      this.loading = true
      this.loadItems(val)
    }
  },
  methods: {
    loadItems (search) {
      search = search || ''
      let extraQuery = ''
      if (this.category !== false) {
        extraQuery = `&filter[product_categories]=${this.category}`
      }
      this.$http.get(`/product-component-types?filter[name]=${search}${extraQuery}&include=components`)
        .then(({data}) => {
          this.paginatedComponentTypes = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
