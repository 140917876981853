export default {
  SET_PAGE (state, newPage) {
    state.page = newPage
  },
  SET_ITEMS (state, data) {
    state.paginatedData = data
  },
  DELETE_FILTER_ITEM (state, filterName) {
    state.page = 1
    delete state.filters[filterName]
  },
  CALCULATE_FILTER (state) {
    state.queryString = `?page=${  state.page  }&sort=${  state.sort  }&${  Object.keys(state.filters).map((key) => {
      return `filter[${  key  }]=${  state.filters[key]}`
    }).join('&')}`
  },
  ADD_FILTER_ITEM (state, {filterName, searchValue}) {
    state.page = 1
    state.filters[filterName] = searchValue
  },
  CHANGE_SORT (state, sort) {
    state.sort = sort
  }
}
