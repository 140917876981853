<template>
  <v-autocomplete
    v-model="selectOption"
    :autocomplete="false"
    :dense="dense"
    :hide-details="hideDetails"
    :items="items"
    :label="label"
    :loading="loading"
    :outlined="outlined"
    hide-no-data
    item-text="name"
    item-value="id"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'ZoneSelector',
  props: {
    value: {},
    label: {
      default: 'Zona',
      type: String
    },
    dense: {
      default: false,
      type: Boolean
    },
    hideDetails: {
      default: false,
      type: Boolean
    },
    outlined: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      paginatedData: {
        data: []
      },
      loading: false
    }
  },
  computed: {
    items () {
      return this.paginatedData.data
    },
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        if (typeof val === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', val)
        }
      }
    }
  },
  methods: {
    loadItems () {
      this.$http.get(`/location/zones?page_size=100`)
        .then(({data}) => {
          this.paginatedData = data
          this.loading = false
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>

