import axios from '@/axios'

const actions = {

  // Vertical NavMenu
  updateVerticalNavMenuWidth ({commit}, width) {
    commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
  },

  // VxAutoSuggest
  updateStarredPage ({commit}, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  // The Navbar
  arrangeStarredPagesLimited ({commit}, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore ({commit}, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },

  toggleContentOverlay ({commit}) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme ({commit}, val) {
    commit('UPDATE_THEME', val)
  },

  updateUserInfo ({commit}, payload) {
    commit('UPDATE_USER_INFO', payload)
  },

  setLanguages ({commit}) {
    axios.get('/helpers/languages')
      .then(({data}) => {
        commit('UPDATE_LANUGAGES', data)
      })
  },

  startLoading ({
    state,
    commit
  }) {
    if (!state.isLoading) {
      commit('START_LOADING')

      this._vm.$vs.loading({type: 'material'})
    }
  },
  stopLoading ({commit}) {
    commit('STOP_LOADING')
    this._vm.$vs.loading.close()
  },

  // eslint-disable-next-line no-unused-vars
  notify ({commit}, notifyOptions) {
    this._vm.$vs.notify(notifyOptions)
  },


  loadClientDiscountList ({
    commit,
    dispatch
  }) {

    return axios.get('/clients/discount-list')
      .then(({data}) => {
        commit('LOAD_CLIENT_DISCOUNT_LIST', data)
      })
      .catch(() => {
        dispatch('notify', {
          title: 'Eroare',
          text: 'A aparut o eroare la incarcare. Va rugam reincarcati pagina sau incercati mai tarziu',
          color: 'danger'
        }, {root: true})
      })

  },

  loadFormSubmissionsStatuses ({commit}) {

    return axios.get('/form-submissions/statuses?page_size=250')
      .then(({data}) => {
        commit('LOAD_FORM_SUBMISSION_STATUSES', data)
      })
      .catch(() => {
        console.log('error on form submission statuses get')
      })

  },

  loadNavigation ({commit}) {
    axios.get('/helpers/nav-menu-items')
      .then(({data}) => {
        commit('UPDATE_NAVIGATION', data)
      })
  }
}

export default actions
