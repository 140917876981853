export default {
  SET_CATEGORY_ID (state, categoryId) {
    state.configData.category_id = categoryId
  },
  SET_BASE_PRODUCT_ID (state, productId) {
    state.configData.base_product_id = productId
  },

  // Subcategories & Qualities
  SET_SUBCATEGORIES (state, subcategories) {
    state.configData.categories = subcategories
  },
  SET_QUALITIES (state, qualities) {
    state.configData.qualities = qualities
  },

  // Product Components
  REPLACE_ALL_SLOTS (state, slots) {
    state.configData.slots = slots
  },
  ADD_SLOT (state, slot) {
    state.configData.slots.push(slot)
  },
  UPDATE_SLOTS (state, slots) {
    state.configData.slots = slots
  },
  ADD_COMPONENT (state, {
    id,
    status,
    uuid
  }) {
    const index = state.configData.slots.findIndex((el) => el.uuid === uuid)
    state.configData.slots[index].components.push({
      id,
      status
    })
  },
  REMOVE_COMPONENT (state, {
    id,
    uuid
  }) {
    const slotIndex = state.configData.slots.findIndex((el) => el.uuid === uuid)
    const componentIndex = state.configData.slots[slotIndex].components.findIndex((el) => el.id === id)
    state.configData.slots[slotIndex].components.splice(componentIndex, 1)
  },
  CHANGE_STATUS (state, {
    id,
    status,
    uuid
  }) {
    const slotIndex = state.configData.slots.findIndex((el) => el.uuid === uuid)
    const componentIndex = state.configData.slots[slotIndex].components.findIndex((el) => el.id === id)
    state.configData.slots[slotIndex].components[componentIndex].status = status
  },

  // Warranty
  ADD_WARRANTY (state, warranty) {
    if (!state.configData.selectedWarranties.includes(warranty)) {
      state.configData.selectedWarranties.push(warranty)
    }
  },
  SET_WARRANTIES (state, warranties) {
    state.configData.selectedWarranties = warranties
  },
  REMOVE_WARRANTY (state, warranty) {
    const index = state.configData.selectedWarranties.indexOf(warranty)
    if (index >= 0) {
      state.configData.selectedWarranties.splice(index, 1)
    }
  },
  ADD_BUFFER_WARRANTY (state, warranty) {
    if (!state.bufferWarranties.find(item => item.id === warranty.id)) {
      state.bufferWarranties.push(warranty)
    }
  },
  SET_BUFFER_WARRANTY (state, warranties) {
    state.bufferWarranties = warranties
  },
  // Extra options
  ADD_EXTRA_OPTION(state, extraOption) {
    console.log(state.configData)
    if (!state.configData.selectedExtraOptions.includes(extraOption)) {
      state.configData.selectedExtraOptions.push(extraOption)
    }
  },
  SET_EXTRA_OPTIONS (state, extraOptions) {
    state.configData.selectedExtraOptions = extraOptions;
  },

  REMOVE_EXTRA_OPTION (state, extraOption) {
    console.log("Removing extra option", extraOption)
    const index = state.configData.selectedExtraOptions.indexOf(extraOption)
    if (index >= 0) {
      state.configData.selectedExtraOptions.splice(index, 1)
    }
  },

  // Publish Locations
  SET_IS_PUBLISHED_TO_CIT (state, value) {
    state.configData.is_published_to_cit = value
  },
  SET_IS_PUBLISHED_TO_RESELLERS (state, value) {
    state.configData.is_published_to_resellers = value
  },
  SET_IS_PUBLISHED_TO_FEED (state, value) {
    state.configData.is_published_to_feed = value
  },

  SET_SHORT_NAME_RECIPE (state, value) {
    state.configData.short_name_recipe = value
  },

  // Errors
  SET_ERRORS (state, errors) {
    state.errors = errors
  },
  CLEAR_ERRORS (state) {
    state.errors = {}
  },

  // Helpers
  ADD_COMPONENT_TYPE_TO_CACHE (state, componentType) {
    state.cachedComponentTypes.push(componentType)
  },
  SET_IS_EDITING (state, isEditing) {
    state.isEditing = isEditing
  },
  RESET (state) {
    state.configData = JSON.parse(JSON.stringify(state.backup))
    state.isEditing = false
    state.errors = {}
    state.bufferWarranties = []
    state.cachedComponentTypes = []
  }
}
