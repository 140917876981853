export default {
  paginatedData (state) {
    return state.paginatedData
  },
  last_page(state){
    return state.paginatedData.meta.last_page
  },
  queryString (state) {
    return state.queryString
  },
  currentPage (state) {
    return state.page
  },
  type(state){
    return state.type
  },
  getItemForEdit (state) {
    return state.editItem
  }
}
