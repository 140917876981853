import axios from '@/axios'

export default {
  storeItem ({dispatch}, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/product-components', itemData)
        .then((response) => {
          dispatch('loadItems').then(() => {
            dispatch('sidebar/closeSidebar', null, {root: true})
            dispatch('stopLoading', null, {root: true})
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          dispatch('stopLoading', null, {root: true})
        })
    })

  },

  setCloneItem ({commit}, item) {
    return new Promise((resolve) => {
      commit('SET_CLONED_ITEM', item)
      resolve(item)
    })
  }
}
