import axios from '@/axios'

export default {
  loadItems ({commit, dispatch}) {
    return axios.get('/users/getUsersByFranchisee')
      .then(({data}) => {
        commit('SET_ITEMS', data)
        commit('MARK_AS_LOADED')
      })
      .catch(() => {
        dispatch('notify', {
          title: 'Eroare',
          text: 'A aparut o eroare la incarcarea agentilor. Va rugam reincarcati pagina sau incercati mai tarziu',
          color: 'danger'
        }, {root: true})
      })
  }
}
