<template>
  <v-app id="app" :class="vueAppClasses" data-app>
    <general-sidebar />
    <v-main>
      <router-view @setAppClasses="setAppClasses" />
    </v-main>
  </v-app>
</template>

<script>
import themeConfig from '@/../themeConfig.js'
import login from '@/login'
import GeneralSidebar from '@/components/GeneralSidebar'
import Editor from '@tinymce/tinymce-vue'
import axios from '@/axios'
let baseURL = process.env.VUE_APP_BASE_URL_API
baseURL = `${baseURL}/public`
export default {
  components: {
    GeneralSidebar,
    editor: Editor
  },
  data() {
    return {
      vueAppClasses: []
    }
  },
  watch: {
    '$store.state.theme'(val) {
      this.toggleClassInBody(val)
    },
    '$vs.rtl'(val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr')
    }
  },
  methods: {
    toggleClassInBody(className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
        document.body.classList.add('theme-dark')
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        document.body.classList.add('theme-semi-dark')
      } else {
        if (document.body.className.match('theme-dark')) document.body.classList.remove('theme-dark')
        if (document.body.className.match('theme-semi-dark')) document.body.classList.remove('theme-semi-dark')
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr)
    },
    handleWindowResize() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    },
    handleScroll() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
    }
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme)
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)

    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  },
  async created() {
    if (window.location.hostname != "admin.citgrup.ro") {
      axios
        .get(baseURL + '/franchisees/getCustomBrandingCssbyDomainForAdmin')
        .then(response => {
          if (response.data) {
            var styleElement = document.createElement('style');
            styleElement.appendChild(document.createTextNode(response.data));
            document.getElementsByTagName('head')[0].appendChild(styleElement);
          }
        })
    }
    const dir = this.$vs.rtl ? 'rtl' : 'ltr'
    document.documentElement.setAttribute('dir', dir)

    login.init()

    window.addEventListener('resize', this.handleWindowResize)
    window.addEventListener('scroll', this.handleScroll)

    if (window.location.hostname != "admin.citgrup.ro") {
      axios
        .get(baseURL + `/franchisees/getLogoUrlsForAdmin`)
        .then(response => {
          if (response.data) {
            const fevicon_admin_url = response.data.fevicon_admin_url
            let favicon = document.querySelector('link[rel="icon"]')
            if (!favicon) {
              favicon = document.createElement('link')
              favicon.setAttribute('rel', 'icon')
              favicon.setAttribute('type', 'image/png')
              document.head.appendChild(favicon)
            }
            fevicon_admin_url && favicon.setAttribute('href', fevicon_admin_url)
          }
        })
    }

    if (window.location.hostname != "admin.citgrup.ro") {
      axios
        .get(baseURL + `/franchisees/getFranchiseeInfoByDomain`)
        .then(response => {
          console.log(response)
          if (response && response.data) {
            console.log(response.data)
            const title = response.data && response.data.name && response.data.name ? `${response.data.name} Admin Panel` : 'Citgrup Admin Panel';
            console.log(title)
            document.title = title;
          }
        })
    }

  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
    window.removeEventListener('scroll', this.handleScroll)
  }
}

</script>
