import axios from '@/axios.js'

export default {
  setTodoSearchQuery ({ commit }, query) {
    commit('SET_TODO_SEARCH_QUERY', query)
  },
  fetchTasks ({ commit, getters }, {filter}) {
    return new Promise((resolve, reject) => {
      const search = getters.todoSearchQuery
      axios.get(`/task-management?filter[filter]=${filter}&filter[title]=${search}`)
        .then((response) => {
          commit('SET_TASKS', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchTags ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/task-management/categories')
        .then((response) => {
          commit('SET_TAGS', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  addTask ({ commit }, task) {
    return new Promise((resolve, reject) => {
      axios.post('/task-management', task)
        .then((response) => {
          commit('ADD_TASK', Object.assign(task, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateTask ({ commit }, task) {
    return new Promise((resolve, reject) => {
      axios.patch(`/task-management/${task.id}`, task)
        .then((response) => {
          commit('UPDATE_TASK', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  markAsCompleted ({commit}, task) {
    return new Promise((resolve, reject) => {
      axios.patch(`task-management/${task.id}/mark-as-complete`, {task})
        .then((response) => {
          commit('MARK_AS_COMPLETED', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  loadAgents ({commit, dispatch}) {
    return axios.get('/users/getUsersByFranchisee')
      .then(({data}) => {
        commit('SET_AGENTS', data)
        commit('MARK_AS_LOADED')
      })
      .catch(() => {
        dispatch('notify', {
          title: 'Eroare',
          text: 'A aparut o eroare la incarcarea agentilor. Va rugam reincarcati pagina sau incercati mai tarziu',
          color: 'danger'
        }, {root: true})
      })
  }
}
