// axios
import axios from 'axios'

let baseURL = process.env.VUE_APP_BASE_URL_API
baseURL = `${baseURL}/app`
export default axios.create({
  baseURL,
  headers: {
    'Accept': 'application/json',
    'Language': 'ro'
  },
  timeout: 0
  // You can add your headers here
})
