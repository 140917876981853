import axios from '@/axios'

export default {
  storeItem ({dispatch}, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/clients/private-clients', itemData)
        .then((response) => {
          dispatch('sidebar/closeSidebar', null, {root: true})
          dispatch('stopLoading', null, {root: true})
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          dispatch('stopLoading', null, {root: true})
        })
    })

  },
  updateItem ({dispatch}, itemData) {
    return new Promise((resolve, reject) => {
      axios.post(`/clients/private-clients/${itemData.id}`, itemData.form)
        .then((response) => {
          dispatch('loadItems').then(() => {
            dispatch('sidebar/closeSidebar', null, {root: true})
          })
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          dispatch('stopLoading', null, {root: true})
        })
    })
  }
}
