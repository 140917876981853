<template>
  <div>
    <span class="text-danger text-xs block" v-for="(error, index) in errors" :key="index">
      {{ error }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessages',
  props: {
    errors: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>
