<template>
  <div>
    <form ref="form">
      <div class="mb-5">
        <div class="mb-5">
          <v-text-field v-model="form.name" label="Nume"/>
          <error-messages :errors="errors.name"></error-messages>
        </div>
        <div class="mb-5">
          <v-text-field v-model="form.display_order" type="number" label="Ordine Afisare"/>
          <error-messages :errors="errors.display_order"></error-messages>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'AddAttributeGroup',
  components: {ErrorMessages},
  data () {
    return {
      form: {
        name: '',
        display_order: ''
      },
      errors: {}
    }
  },
  methods: {
    submitForm () {
      this.$store.dispatch('attributeValueGroup/storeItem', this.form)
        .catch(({response}) => {
          this.errors = response.data.errors
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
