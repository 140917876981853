export default {
  modalStatus: false,
  editItem: {
    category_id: '',
    name: '',
    internal_name: '',
    model: '',
    price: '',
    buying_price: '',
    stock: '',
    stock_status: '',
    featured_image: false,
    images: [],
    brand_id: '',
    receiving_at: '',
    description: ''
  }
}
