export default {
  paginatedData: {
    meta: {
      current_page: null,
      from: null,
      last_page: null,
      per_page: null,
      prev_page_url: null,
      to: null,
      total: null
    },
    data: []
  },
  sort: '',
  page: 1,
  filters: {},
  queryString: ''

}
