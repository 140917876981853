import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import auth from './auth/module'
import sidebar from './sidebar/module'
import users from './users/module'
import components from './components/module'
import products from './products/module'
import general from './general/module'
import companyClients from './ClientsCompany/module'
import publicClients from './ClientsPublic/module'
import itClients from './ClientsIt/module'
import privateClients from './ClientsPrivate/module'
import resellers from './resellers/module'
import agents from './agents/module'
import baseProducts from './base-products/module'
import clientType from './client-type/module'
import CategoryFilters from './category-filters/module'
import Roles from './roles/module'
import taskManagement from './TaskManagement/module'
import attributes from './attributes/module'
import rewards from './rewards/module'
import banners from './banners/module'
import singleClient from './SingleClient/module'
import activity from './activity/module'
import productGenerator from './productGenerator/module'
import contactMessages from './contact-messages/module'
import featuredSpecsFilters from './featured-specs-filters/module'
import customerActivityHistory from './customerActivityHistory/module'
import userActivityHistory from './userActivityHistory/module'
import baseProductEditor from './base-product-editor/module'
import oldResellers from './oldCitCustomers/oldResellers/module'
import attributeValues from './attributeValues/module'
import attributeValueGroup from './AttributeGroups/module'
import portfolioCustomers from './portfolio-customers/module'
import testimonials from './testimonials/module'
import singleFranchisee from './SingleFranchisee/module'
import franchisees from './franchisees/module'

Vue.use(Vuex)


export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    agents,
    productGenerator,
    sidebar,
    users,
    components,
    products,
    general,
    companyClients,
    publicClients,
    itClients,
    privateClients,
    resellers,
    baseProducts,
    clientType,
    CategoryFilters,
    Roles,
    taskManagement,
    attributes,
    rewards,
    banners,
    singleClient,
    activity,
    contactMessages,
    featuredSpecsFilters,
    customerActivityHistory,
    userActivityHistory,
    baseProductEditor,
    oldResellers,
    attributeValues,
    attributeValueGroup,
    portfolioCustomers,
    testimonials,
    singleFranchisee,
    franchisees
  }
})
