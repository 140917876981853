export default {
  paginatedData (state) {
    return state.paginatedData.data
  },
  queryString (state) {
    return state.queryString
  },
  currentPage (state) {
    return state.page
  },
  last_page (state) {
    return state.paginatedData.last_page
  },

  getItemForEdit (state) {
    return state.editItem
  }
}

