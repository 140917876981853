<template>
  <div>
    <form autocomplete="off">

      <div>
        <v-text-field v-model="form.client.first_name" label="Nume" @change="form.client.first_name = $event.target.modelValue , $event.target.modelValue ? errors['client.first_name'] = '' : errors['client.first_name'],console.log($event.target.modelValue)"/>
        <error-messages :errors="errors['client.first_name']"/>
      </div>

      <div>
        <v-text-field v-model="form.client.last_name" label="Prenume"/>
        <error-messages :errors="errors['client.last_name']"/>
      </div>

      <div>
        <v-text-field v-model="form.client.phone" label="Nr. Telefon"/>
        <error-messages :errors="errors['client.phone']"/>
      </div>

      <div>
        <v-text-field v-model="form.client.email" label="Email"/>
        <error-messages :errors="errors['client.email']"/>
      </div>

      <div>
        <zone-selector v-model="form.client.zone_id" class="mb-4" label="Zona"/>
        <error-messages :errors="errors['client.zone_id']"/>
      </div>

      <div>
        <county-selector v-model="form.client.county_id" :include-all="true" class="mb-4" label="Judetul"/>
        <error-messages :errors="errors['client.county_id']"/>
      </div>

      <div>
        <city-selector v-model="form.client.city_id" :county="form.client.county_id" class="mb-4"
                       label="Localitatea"/>
        <error-messages :errors="errors['client.city_id']"/>
      </div>

    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import ZoneSelector from '@/components/autocompletes/ZoneSelector'
import CountySelector from '@/components/autocompletes/CountySelector'
import CitySelector from '@/components/autocompletes/CitySelector'

export default {
  name: 'AddItClient',
  components: {
    CitySelector,
    CountySelector,
    ZoneSelector,
    ErrorMessages
  },
  data() {
    return {
      form: {
        client: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
          county_id: '',
          zone_id: '',
          city_id: '',
          type: ''
        }
      },
      errors: {}
    }
  },
  methods: {
    submitForm() {

      this.$store.dispatch('itClients/storeItem', this.form)
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })

    }
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
