export default {
  modalStatus (state) {
    return state.modalStatus
  },
  getItemForEdit (state) {
    return state.editItem
  },
  category_id (state) {
    return state.editItem.category_id
  },
  receiving_at (state) {
    return state.editItem.receiving_at
  },
  name (state) {
    return state.editItem.name
  },
  internal_name (state) {
    return state.editItem.internal_name
  },
  model (state) {
    return state.editItem.model
  },
  price (state) {
    return state.editItem.price
  },
  buying_price (state) {
    return state.editItem.buying_price
  },
  stock (state) {
    return state.editItem.stock
  },
  stock_status (state) {
    return state.editItem.stock_status
  },
  featured_image (state) {
    return state.editItem.featured_image
  },
  images (state) {
    return state.editItem.images
  },
  brand_id (state) {
    return state.editItem.brand_id
  },
  description (state) {
    return state.editItem.description
  }
}
