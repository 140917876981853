<template>
  <v-select
    v-model="selectOption"
    :items="badges"
    chips
    small-chips
    dense
    hide-details
    item-text="name"
    item-value="id"
    label="Badge-uri"
    multiple
    outlined
    persistent-hint
  ></v-select>
</template>

<script>

export default {
  name: 'BadgeSelector',
  props: {
    value: {},
    label: {
      default: 'Badge'
    },
    returnObject: {
      default: false
    }
  },
  data () {
    return {
      badges: [],
      loading: false
    }
  },
  computed: {
    selectOption: {
      get () {
        return this.value
      },
      set (val) {
        val = val || ''
        this.$emit('input', val)

      }
    }
  },
  methods: {
    loadItems () {
      this.$http.get('/badges')
        .then(({data}) => {
          this.badges = data
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'Eroare la incarcare! va rugam incercati mai tarziu',
            color: 'danger'
          })
        })

    }
  },
  created () {
    this.loadItems()
  }
}
</script>
