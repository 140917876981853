<template>
  <div>
    <form ref="form">
      <div class="mb-5">
        <vs-input v-model="form.name" label="Name" placeholder="Placeholder" width="100%"/>
        <error-messages :errors="errors.name"></error-messages>
      </div>
      <div v-if="!!previewLogo" class="mb-5">
        <img :src="previewLogo" alt="" class="max-w-full">
      </div>
      <div class="mb-5">
        <label class="vs-select--label">Incarca Logo-ul <small>(Maxim 1MB si 500x500 px)</small></label>
        <vs-upload
          ref="upload"
          :show-upload-button="false"
          name="logo"
          text="Cauta si inlocuieste logo-ul"
          @change="setUploadFile"
        />
        <error-messages :errors="errors.logo"></error-messages>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import lodash from 'lodash'

export default {
  name: 'EditPortfolioCustomers',
  components: {ErrorMessages},
  data () {
    return {
      form: {
        name: '',
        logo: ''
      },
      errors: {},
      previewLogo: ''
    }
  },
  computed: {},
  methods: {
    setUploadFile () {
      this.form.logo = this.$refs.upload.filesx.splice(-1)[0]
      this.previewLogo = false
    },
    submitForm () {
      this.$store.dispatch('startLoading')

      const formData = new FormData()
      formData.append('name', this.form.name)
      formData.append('logo', this.form.logo)

      this.$store.dispatch('portfolioCustomers/updateItem', {form: formData, id: this.form.id})
        .catch(({response}) => {
          this.errors = response.data.errors
        })

    }
  },
  mounted () {
    const item = this.$store.getters['portfolioCustomers/getItemForEdit']
    this.form = lodash.cloneDeep(item)
    this.previewLogo = lodash.clone(this.form.logo)
  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
    margin: 0;
  }
}
</style>
