<template>
  <div>
    <form ref="form">
      <div class="mb-5">
        <v-text-field v-model="form.name" label="Nume"/>
        <error-messages :errors="errors.name"></error-messages>
      </div>
      <div class="mb-5">
        <v-text-field v-model="form.milestone" type="number" label="Puncte necesare"/>
        <error-messages :errors="errors.milestone"></error-messages>
      </div>
      <div class="mb-5">
        <v-textarea v-model="form.description" label="Descriere"></v-textarea>
        <error-messages :errors="errors.description"></error-messages>
      </div>
      <div class="mb-5">
        <label class="vs-select--label">Incarca imaginea reprezentativa <br/><small>(Maxim 1MB si 500x500 px)</small></label>
        <vs-upload
          ref="upload"
          :show-upload-button="false"
          name="image"
          text="Cauta si incarca imaginea" @change="setUploadFile"
        />
        <error-messages :errors="errors.image"></error-messages>
      </div>
    </form>
  </div>
</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import {EventBus} from '@/plugins/event-bus'

export default {
  name: 'AddNewClientReward',
  components: {ErrorMessages},
  data () {
    return {
      form: {
        name: '',
        milestone: '',
        description: '',
        image: ''
      },
      errors: {}
    }
  },
  computed: {},
  methods: {
    submitForm () {
      this.$store.dispatch('startLoading')

      const formData = new FormData()
      formData.append('name', this.form.name)
      formData.append('image', this.form.image)
      formData.append('milestone', this.form.milestone)
      formData.append('description', this.form.description)

      this.$http.post('rewards', formData)
        .then(() => {
          EventBus.$emit('created')
          this.$store.dispatch('sidebar/closeSidebar')
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              title: 'Eroare',
              text: 'Nu toate campurile obligatorii au fost completate.',
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Eroare',
              text: 'A aparut o eroare. Va rugam reincarcati pagina sau incercati mai tarziu',
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })

    },
    setUploadFile () {
      this.form.image = this.$refs.upload.filesx[0]
    }

  }
}
</script>

<style lang="scss" scoped>
.vs-con-input-label {
  width: auto;
}
</style>
<style lang="scss">
.con-img-upload {
  padding: 0;

  .con-input-upload {
    width: 100%;
    height: 48px;
  }
}
</style>
