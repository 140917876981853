import axios from '@/axios'

export default {
  storeItem ({dispatch}, itemData) {
    return new Promise((resolve, reject) => {
      axios.post('/clients/franchisees/createFranchisee', itemData)
        .then((response) => {
            dispatch('sidebar/closeSidebar', null, {root: true})
            dispatch('stopLoading', null, {root: true})
            resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
        .finally(() => {
          dispatch('stopLoading', null, {root: true})
        })
    })

  }
}