import axios from '@/axios'
import lodash from 'lodash'
import {getUuid} from '@/helpers'

export default {


    regenerate({dispatch, getters, commit}, productConfigId) {
        commit('CLEAR_ERRORS')
        dispatch('startLoading')
        return new Promise((resolve) => {
            axios.patch(`/product-configs/${productConfigId}`, getters.configData)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        commit('SET_ERRORS', error.response.data.errors)
                        dispatch('notifyError', 'Au fost gasite erori de validare pentru unul sau mai multe campuri!')
                    } else {
                        dispatch('notifyError', 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu!')
                    }
                })
                .finally(() => {
                    dispatch('stopLoading')
                })
        })
    },
    generate({dispatch, getters, commit}) {
        commit('CLEAR_ERRORS')
        dispatch('startLoading')
        return new Promise((resolve) => {
            axios.post('/product-configs', getters.configData)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        commit('SET_ERRORS', error.response.data.errors)
                        dispatch('notifyError', 'Au fost gasite erori de validare pentru unul sau mai multe campuri!')
                    } else {
                        dispatch('notifyError', 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu!')
                    }
                })
                .finally(() => {
                    dispatch('stopLoading')
                })
        })
    },

    setQualitiesForCategory({commit, getters}, {categoryId, qualities}){
        const selectedQualities = {...getters.selectedQualities, [categoryId]: qualities}
        commit('SET_QUALITIES', selectedQualities)
    },

    // removeQuality({dispatch, getters, commit}, {categoryId, qualityId}) {
    //     let selectedQualities = ''
    //     if (getters.selectedQualities[categoryId].length > 0) {
    //         selectedQualities = getters.selectedQualities[categoryId].filter(item => item.id != qualityId)
    //     } else {
    //         delete getters.selectedQualities[categoryId]
    //         selectedQualities = JSON.parse(JSON.stringify(getters.selectedQualities))
    //     }
    //     commit('SET_QUALITIES', selectedQualities)
    // },
    // selectQuality({dispatch, getters, commit}, {categoryId, qualityId}) {
    //     let selectedQualities = '';
    //     if (typeof getters.selectedQualities[categoryId] === 'undefined') {
    //         selectedQualities = {...getters.selectedQualities, [categoryId]: [qualityId]}
    //     } else {
    //         selectedQualities = JSON.parse(JSON.stringify(getters.selectedQualities[categoryId])).push(qualityId)
    //     }
    //     commit('SET_QUALITIES', selectedQualities)
    // },

    // Components
    addSlot({getters, commit}, componentType) {
        const order = getters.slots.length + 1

        commit('ADD_SLOT', {
            display_order: order,
            component_type_id: componentType.id,
            uuid: getUuid(10),
            components: []
        })
        const cachedComponentTypes = getters.cachedComponentTypes
        if (!lodash.find(cachedComponentTypes, o => o.id === componentType.id)) {
            commit('ADD_COMPONENT_TYPE_TO_CACHE', componentType)
        }
    },
    moveLeft({commit, getters}, slot) {
        const localSlots = JSON.parse(JSON.stringify(getters.slots))
        const index = lodash.findIndex(localSlots, (e) => {
            return e.display_order === slot.display_order
        }, 0)
        localSlots[index].display_order -= 1
        localSlots[index - 1].display_order += 1
        const newLocalSlots = lodash.sortBy(localSlots, 'display_order')
        commit('UPDATE_SLOTS', newLocalSlots)
    },
    moveRight({commit, getters}, slot) {
        const localSlots = JSON.parse(JSON.stringify(getters.slots))
        const index = lodash.findIndex(localSlots, (e) => {
            return e.display_order === slot.display_order
        }, 0)
        localSlots[index].display_order += 1
        localSlots[index + 1].display_order -= 1
        const newLocalSlots = lodash.sortBy(localSlots, 'display_order')
        commit('UPDATE_SLOTS', newLocalSlots)
    },
    removeSlot({commit, getters}, componentType) {
        const newSlots = JSON.parse(JSON.stringify(getters.slots))
        const shouldChangeOrder = (getters.slotsCount > 1)
        let found = false
        for (let i = 0; i < newSlots.length; i++) {
            if (newSlots[i].uuid === componentType.uuid) {
                newSlots.splice(i, 1)
                found = true
            }else{
              if (found === true && shouldChangeOrder) {
                  newSlots[i].display_order -= 1
              }
            }
        }
        commit('UPDATE_SLOTS', newSlots)
    },
    selectComponent({commit}, componentData) {
        commit('ADD_COMPONENT', componentData)
    },
    deselectComponent({commit}, componentData) {
        commit('REMOVE_COMPONENT', componentData)
    },
    changeStatusOnComponent({commit}, componentData) {
        commit('CHANGE_STATUS', componentData)
    },

  // Extra Warranty

  selectWarranty ({commit},warranty) {
    commit("ADD_WARRANTY", warranty)
  },
  deleteWarranty ({commit},warranty) {
    commit("REMOVE_WARRANTY", warranty)
  },
  addBufferWarranty ({commit},warranty) {
    commit("ADD_BUFFER_WARRANTY", warranty)
  },

  // Extra options
  selectExtraOption({commit}, extraOption) {
    commit("ADD_EXTRA_OPTION", extraOption)
  },
  deleteExtraOption({commit},extraOption) {
    commit("REMOVE_EXTRA_OPTION", extraOption)
  },
  // Helpers
    startLoading({dispatch}) {
        dispatch('startLoading', null, {root: true})
    },
    stopLoading({dispatch}) {
        dispatch('stopLoading', null, {root: true})
    },
    notifyError({dispatch}, errorText) {
        const errorObj = {
            title: 'Eroare',
            text: errorText,
            color: 'danger'
        }
        dispatch('notify', errorObj, {root: true})
    }
}
