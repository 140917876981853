function getUuid (length) {
  let uuid = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    uuid += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return uuid
}

function groupBy (array, key) {
  return array.reduce(
    (objectsByKeyValue, obj) => ({
      ...objectsByKeyValue,
      [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
    }),
    {}
  )
}

export {getUuid, groupBy}
