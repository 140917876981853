export default {
  paginatedData (state) {
    return state.paginatedData
  },
  queryString (state) {
    return state.queryString
  },
  currentPage (state) {
    return state.page
  },
}
